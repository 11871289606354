import {get,post} from "@/utils/axios"

const loginMail = p => get("/api-seller/apiconsole/user/loginMail", p);

const modifyPwd = p => post("/api-seller/apiconsole/user/syncPassword", p);

export default {
    loginMail,
    modifyPwd,
}
