<template >
  <div class="page flex-col">
    <div class="group_1 flex-row" >
      <div class="group_1_left">修改密码</div>
    </div>
    <el-row class="group_2 flex-row" >
      <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">

        <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="width: 630px;">
          <el-form-item label="邮箱账号：">
<!--            <el-select v-model="form.region" placeholder="请选择活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>-->
            <el-input
                placeholder="邮箱账号"
                v-model="userInfo.email"
                :disabled="true" style="width: 70%">
            </el-input>

          </el-form-item>
<!--          <el-form-item label="活动时间">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
            </el-col>
          </el-form-item>-->
          <el-form-item label="输入验证码：" prop="region">
<!--            <el-switch v-model="form.delivery"></el-switch>-->
            <el-input v-model="form.region" placeholder="请输入验证码" style="width: 70%"></el-input>
            <el-button type="danger" plain style="padding: 0.5vw 1vw 0.5vw 1vw;margin-left:10px" @click="loginMail" :disabled="ifDisabledSend">{{ sendTitle }}</el-button>
          </el-form-item>
          <el-form-item label="输入新密码：" prop="pwd">
            <el-input placeholder="输入新密码" v-model="form.pwd" show-password style="width: 70%"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="checkPwd">
            <el-input placeholder="确认密码" v-model="form.checkPwd" show-password style="width: 70%"></el-input>
          </el-form-item>
<!--          <el-form-item label="问题描述：">
            <el-input type="textarea" v-model="form.desc" ></el-input>
          </el-form-item>-->
          <el-form-item>
<!--            <el-button @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>-->
            <el-button type="danger" plain @click="updatePwd" style="padding: 0.5vw 1vw 0.5vw 1vw;">确定</el-button>
            <el-button type="warning" plain style="padding: 0.5vw 1vw 0.5vw 1vw;">取消</el-button>

          </el-form-item>
        </el-form>
      </el-col>

    </el-row>

  </div>
</template>

<script>
import api from '@/api/buyer/editAccountApi.js'
import md5Js from '@/utils/md5'
import CryptoJS from "crypto-js";

export default {
  name: "buyerIndex",
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        pwd: '',
          checkPwd: '',
      },
    rules: {
      region: [{required: true, message: '请输入验证码', trigger: 'change'}],
      pwd: [{required: true, trigger: 'change', validator: (rule, val, cb) => {
            if(!val) {
                cb(new Error('请输入密码'));
                return;
            }
            //let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
            var reg = /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{6,20}$/;
            if(!reg.test(val)) {
                //cb(new Error('密码必须为8-16位，必须包含字母和数字'))
                cb(new Error("至少包含英文大小写、数字、特殊字符其中3种"));
                return;
            }
            cb();
      }}],
      checkPwd: [{required: true, trigger: 'change', validator: (rule, val, cb) => {
          if(!val) {
              cb(new Error('请再次输入密码'));
              return;
          }
          if(this.form.pwd !== val) {
              cb(new Error('两次密码输入不一致'));
              return;
          }
          cb();
      }}]
    },
    userInfo: {},
    sendTitle: '获取验证码',
    ifDisabledSend: false,

    }
  },
  mounted() {

  },
  methods: {
      updatePwd() {
          this.$refs.form.validate(valid => {
              if(valid) {
                  api.modifyPwd({
                      email: this.userInfo.email,
                      mailCode: this.form.region,
                      password: md5Js.hex_md5(this.form.pwd),
                  }).then(res => {
                      if(res.code !== 200) {
                          this.buyerMsg(res.message, 'error');
                          return;
                      }
                      this.buyerMsg(res.message);
                      localStorage.removeItem('accessToken')
                      this.$store.commit('logout');
                      // 清除cookie
                      this.setCookie('', '', -1);
                      this.$router.push({
                          path: '/login'
                      });
                  })
              }
          })
      },
      countDown() {
        let seconds = 60;
        let interval = setInterval(() => {
            seconds--;
            if(seconds === 0) {
                this.ifDisabledSend = false;
                clearInterval(interval);
                this.sendTitle = '获取验证码';
            }
            this.sendTitle = seconds + '秒后重新发送';
        }, 1000);
      },
      loginMail() {
          this.ifDisabledSend = true;
          api.loginMail({
              email: this.userInfo.email
          }).then(res => {
              if(res.code !== 200) {
                  this.buyerMsg(res.message, 'error');
                  this.ifDisabledSend = false;
                  return;
              }
              this.buyerMsg(res.message);
              this.countDown();
          })
      },
      setCookie(portId, psw, exdays) {
        // Encrypt，加密账号密码
        var cipherPortId = CryptoJS.AES.encrypt(
            portId + '',
            'secretkey123'
        ).toString()
        var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey123').toString()
        console.log(cipherPortId + '/' + cipherPsw)// 打印一下看看有没有加密成功

        var exdate = new Date() // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
        // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
        window.document.cookie =
            'currentPortId' +
            '==' +
            cipherPortId +
            ';path=/;expires=' +
            exdate.toGMTString()
        window.document.cookie =
            'password' +
            '==' +
            cipherPsw +
            ';path=/;expires=' +
            exdate.toGMTString()
      },
      onSubmit() {
      }
  },
  created() {
      this.userInfo = this.$store.state.userInfo.userInfo;
      this.form.region = this.userInfo.eamil;

  },

}
</script>
<!--<style>

@import '@/css/dark-style-table.css';

</style>-->
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@import './EditAccount.scss';


/*/deep/ .el-pager li.active{
  color: rgb(247, 81, 69);

  cursor: default;
}
/deep/ .el-select .el-input.is-focus .el-input__inner{
  border-color: rgb(247, 81, 69);
}
/deep/ .el-input__inner:focus{
  border-color: rgb(247, 81, 69);
}*/
/deep/ .el-textarea__inner{
  height: 20vh;
}
@media screen and (max-width: 767px) {

}

/* 4. 大桌面显示器（大于等于768px）布局容器宽度为768px */
@media screen and (min-width: 768px) {
}


</style>
